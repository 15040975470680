import {NavLink} from '@remix-run/react';
import {HTMLAttributes, ReactNode, ElementType} from 'react';
import {twMerge} from 'tailwind-merge';
import {ChevronLeftIcon} from '~/assets/Icons';
import {Link} from '@remix-run/react';
import {MagicLogo} from '~/assets/Logos';

interface Props extends HTMLAttributes<HTMLButtonElement> {
  mode?: 'ruby' | 'default' | 'honey' | 'rubyInverted';
  disabled?: boolean;
  className?: string;
  children: ReactNode;
  to?: string;
  href?: string;
}

const modeClasses = {
  ruby: 'border-ruby-800 border-2 bg-primary text-night-100 hover:bg-ruby-800',
  default:
    'bg-transparent text-heading border border-border enabled:hover:bg-ruby enabled:hover:text-background',
  honey:
    'bg-honey-1100 text-night-1100 enabled:hover:bg-honey-900 enabled:border-2 border-honey-900',
  rubyInverted:
    'bg-transparent text-primary border border-ruby-900 hover:bg-primary hover:text-background',
};

export const Button = ({
  mode = 'default',
  disabled,
  children,
  className,
  to,
  href,
  ...props
}: Props) => {
  const completeClassName = twMerge(
    'flex h-12 cursor-pointer w-max items-center justify-center gap-1 rounded-md px-4 text-center font-medium transition-colors focus:outline-none',
    modeClasses[mode],
    disabled && 'opacity-50 cursor-not-allowed hover:',
    className,
  );

  if (href) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        className={completeClassName}
      >
        {children}
      </a>
    );
  }
  if (to) {
    return (
      <NavLink to={to} className={completeClassName}>
        {children}
      </NavLink>
    );
  } else {
    return (
      <button className={completeClassName} disabled={disabled} {...props}>
        {children}
      </button>
    );
  }
};

interface BackButtonProps {
  to?: string;
}

export const BackButton = ({to = '/'}) => (
  <Link
    to={to}
    className="w-9 h-9 text-night-600 rounded-md border border-night-600 flex items-center justify-center cursor-pointer"
  >
    <ChevronLeftIcon className="w-6 h-6" />
  </Link>
);
